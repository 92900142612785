// open external links in new tab

const externalLinks = (limitScoptByClass) => {
  // find a tag with an href attribute in the limitScoptByClass a class name
  let links = null;
  if (limitScoptByClass) {
    links = document.querySelectorAll(`.${limitScoptByClass} a[href]`);
  } else {
    links = document.querySelectorAll('a[href]')
  }
  for (
    var c = links, a = 0;
    a < c.length;
    a++
  ) {
    var b = c[a];
    b.getAttribute('href') &&
      b.hostname !== location.hostname &&
      (b.target = '_blank');
  }
};

export default externalLinks;
